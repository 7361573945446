import { Box, IconButton, Tooltip } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

type TooltipType = {
  heading: any;
  tooltip: string;
  link?: string;
  target?: string;
};

const TooltipComponent = ({ heading, tooltip, link, target }: TooltipType) => {
  const tooltipHeading = heading;
  const tooltipText = tooltip;
  const iconLink = link ?? '';
  return (
    <Box display="flex" alignContent="center" alignItems="center">
      <span>{tooltipHeading}</span>
      <span>
        <Tooltip
          enterDelay={200}
          leaveDelay={250}
          title={<div style={{ whiteSpace: 'pre-line' }}>{tooltipText}</div>}
        >
          <IconButton size="small" href={iconLink} target={target}>
            <HelpOutlineIcon />
          </IconButton>
        </Tooltip>
      </span>
    </Box>
  );
};

export default TooltipComponent;
