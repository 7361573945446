import { Link } from 'react-router-dom';

import { Tab, Tabs } from '@material-ui/core';

import useUserContext from 'lib/hooks/useUserContext';

type CampaignTabsProps = {
  value: number;
};

// This is so we can follow DRY principles
export default function CampaignTabs({ value }: CampaignTabsProps) {
  const { isWorkspaceAgent } = useUserContext();

  return (
    <Tabs textColor="secondary" value={value}>
      <Tab component={Link} label="Ad Campaigns" to="/campaigns" />
      <Tab component={Link} label="View By Agent" to="/campaigns/agents" />
      <Tab component={Link} label="Calendar" to="/campaigns/calendar" />
      <Tab component={Link} label="Analytics" to="/campaigns/analytics" />
      {!isWorkspaceAgent && (
        <Tab component={Link} label="Monthly Spend" to="/campaigns/reports/monthly-spend" />
      )}
      {!isWorkspaceAgent && (
        <Tab component={Link} label="Status & Budget" to="/campaigns/reports/status-budget" />
      )}
    </Tabs>
  );
}
