import { useMemo, useState } from 'react';

import { Bar } from 'react-chartjs-2';
import { Link } from 'react-router-dom';

import { NetworkStatus } from '@apollo/client';

import {
  Box,
  CircularProgress,
  Link as MuiLink,
  MenuItem,
  Paper,
  Select,
  Typography
} from '@material-ui/core';

import MaterialTable from '@material-table/core';

import { ChartOptions } from 'chart.js';
import _ from 'lodash';
import PodiumBronzeIcon from 'mdi-material-ui/PodiumBronze';
import PodiumGoldIcon from 'mdi-material-ui/PodiumGold';
import PodiumSilverIcon from 'mdi-material-ui/PodiumSilver';
import moment from 'moment';
import numbro from 'numbro';

import {
  Workspace_Resource_Permission_Modes_Enum,
  Workspace_Analytics_Popular_Campaigns_Bool_Exp,
  useWorkspaceCampaignAnalyticsQuery,
  Workspace_Analytics_Workspaces_Monthly_Bool_Exp,
  WorkspaceCampaignAnalyticsQuery
} from 'generated/graphql';

import PageHeader from 'components/PageHeader';
import TableContainer from 'components/TableContainer';

import { useHasuraRoleContext } from 'lib/HasuraRoleContext';
import { useAgentPermissions } from 'lib/hooks/useAgentPermissions';
import useUserContext from 'lib/hooks/useUserContext';

import EmptyChartMessage from 'pages/workspace/Dashboard/components/EmptyChartMessage';
import LoadingChartIndicator from 'pages/workspace/Dashboard/components/LoadingChartIndicator';

import CampaignTabs from '../CampaignTabs';

const options: ChartOptions<'bar'> = {
  plugins: {
    title: {
      display: false,
      text: 'Analytics by campaign',
      font: {
        family: 'DM Sans, sans-serif',
        size: 12
      }
    }
  },
  scales: {
    left: {
      axis: 'y',
      display: 'auto',
      type: 'linear',
      position: 'left'
    },
    right: {
      axis: 'y',
      display: 'auto',
      type: 'linear',
      position: 'right'
    }
  },
  responsive: true
};

export default function CampaignAnalyticsPage() {
  const { workspaceMemberContext } = useHasuraRoleContext();
  const { userId, activeWorkspaceId, workspace, isWorkspaceAgent = false } = useUserContext();
  const agentPermissions = useAgentPermissions();

  const [periodMonths, setPeriodMonths] = useState<number>(6);
  const [startDate, endDate] = useMemo(() => {
    return [
      moment().subtract(periodMonths, 'months').startOf('month').format('YYYY-MM-DD'),
      moment().endOf('month').format('YYYY-MM-DD')
    ];
  }, [periodMonths]);

  const { workspaceWhere, campaignsWhere } = useMemo(() => {
    const workspaceWhere: Workspace_Analytics_Workspaces_Monthly_Bool_Exp = {
      _and: [
        { workspace_id: { _eq: activeWorkspaceId! } },
        { month: { _gte: startDate } },
        { month: { _lte: endDate } }
      ]
    };

    const campaignsWhere: Workspace_Analytics_Popular_Campaigns_Bool_Exp = {
      _and: [
        { workspace_id: { _eq: activeWorkspaceId! } },
        { published_at: { _is_null: false } },
        { published_at: { _gte: startDate } },
        { published_at: { _lte: endDate } },
        { impressions: { _gt: 0 } }
      ]
    };

    if (isWorkspaceAgent) {
      campaignsWhere._and!.push({
        campaign: {
          _or: [
            {
              created_by_id: { _eq: userId }
            },
            {
              agent_permission_mode: { _eq: Workspace_Resource_Permission_Modes_Enum.All }
            },
            {
              assigned_users: {
                user_id: { _eq: userId }
              }
            },
            {
              listings: {
                listing: {
                  agents: { user: { display_name: { _in: agentPermissions.allowed_agents } } }
                }
              }
            }
          ]
        }
      });
    }

    return {
      workspaceWhere,
      campaignsWhere
    };
  }, [
    userId,
    activeWorkspaceId,
    startDate,
    endDate,
    isWorkspaceAgent,
    agentPermissions.allowed_agents
  ]);

  const { data, loading, networkStatus } = useWorkspaceCampaignAnalyticsQuery({
    variables: {
      campaign_analytics_where: workspaceWhere,
      campaigns_where: campaignsWhere
    },
    notifyOnNetworkStatusChange: true,
    context: workspaceMemberContext,
    skip: !activeWorkspaceId
  });

  const chartLabels = useMemo(() => {
    return _.uniq(
      data?.by_month?.map((d) => moment(d.month as string, 'YYYY-MM-DD').format('MMMM YYYY'))
    );
  }, [data?.by_month]);

  const chartData = useMemo(() => {
    const seriesData = data?.by_month ?? [];

    const summed = _.chain(seriesData)
      .groupBy('month')
      .map((d) => ({
        month: d[0].month,
        impressions: _.sumBy(d, 'impressions'),
        spend: _.sumBy(d, 'spend'),
        clicks: _.sumBy(d, 'clicks'),
        video_views: _.sumBy(d, 'video_views')
      }))
      .orderBy('month', 'asc')
      .value();

    return {
      labels: chartLabels,
      datasets: [
        {
          label: 'Impressions',
          data: summed.map((d) => d.impressions),
          backgroundColor: '#17457A',
          yAxisID: 'left'
        },
        {
          label: 'Clicks',
          data: summed.map((d) => d.clicks),
          backgroundColor: '#f1b110',
          yAxisID: 'left'
        },
        {
          label: 'Video Views',
          data: summed.map((d) => d.video_views),
          backgroundColor: '#0aab52',
          yAxisID: 'left'
        },
        {
          label: 'Spend ($) (RHS)',
          data: summed.map((d) => d.spend),
          backgroundColor: '#F47D6F',
          yAxisID: 'right'
        }
      ]
    };
  }, [chartLabels, data?.by_month]);

  const popularCampaigns = useMemo(
    () => data?.popular_campaigns?.map((p, idx) => ({ index: idx + 1, ...p })) ?? [],
    [data?.popular_campaigns]
  );

  if (!workspace) {
    return <CircularProgress />;
  }

  const isLoading =
    loading || networkStatus === NetworkStatus.refetch || networkStatus === NetworkStatus.loading;

  return (
    <>
      <PageHeader title="Campaign analytics" />
      <CampaignTabs value={3} />
      {!isWorkspaceAgent && (
        <Paper style={{ padding: 4 }} elevation={1}>
          <Box
            style={{
              padding: '16px 0px 8px 16px',
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              gap: 30
            }}
          >
            <Typography variant="h6">Campaign analytics by month</Typography>
            <DateRangeSelect value={periodMonths} setValue={setPeriodMonths} />
          </Box>

          {isLoading ? (
            <LoadingChartIndicator />
          ) : data?.by_month?.length ? (
            <Bar style={{ maxHeight: 600 }} data={chartData} options={options} />
          ) : (
            <EmptyChartMessage message="Not enough data available" />
          )}
        </Paper>
      )}
      <Paper style={{ marginTop: 12, padding: 4 }} elevation={1}>
        <Box
          style={{
            padding: '16px 0px 8px 16px',
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            gap: 30
          }}
        >
          <Typography variant="h6">Most viewed campaigns</Typography>
          <DateRangeSelect value={periodMonths} setValue={setPeriodMonths} />
        </Box>
        <MaterialTable
          data={popularCampaigns}
          columns={[
            {
              title: 'id',
              field: 'campaign.id',
              hidden: true
            },
            {
              title: '',
              field: 'index',
              width: 10,
              render: RenderTrophyColumn
            },
            {
              title: 'Campaign Name',
              field: 'campaign.name',
              render: RenderNameColumn
            },
            {
              title: 'Published Date',
              field: 'campaign.published_at',
              type: 'datetime',
              render: RenderPublishDateColumn
            },
            {
              title: 'Total Impressions',
              field: 'campaign.campaign_overall_analytics.impressions',
              type: 'numeric',
              render: (rowData) =>
                numbro(rowData.campaign?.campaign_overall_analytics?.impressions).format({
                  thousandSeparated: true
                })
            },
            {
              title: 'CPM',
              field: 'campaign.campaign_overall_analytics.cpm',
              type: 'currency',
              render: (rowData) =>
                numbro(rowData.campaign?.campaign_overall_analytics?.cpm).formatCurrency({
                  thousandSeparated: true,
                  mantissa: 2
                })
            },
            {
              title: 'Total Engagement',
              field: 'campaign.campaign_overall_analytics.engagement',
              type: 'numeric',
              render: (rowData) =>
                numbro(rowData.campaign?.campaign_overall_analytics?.engagement).format({
                  thousandSeparated: true
                })
            },
            {
              title: 'Total Clicks',
              field: 'campaign.campaign_overall_analytics.clicks',
              type: 'numeric',
              render: (rowData) =>
                numbro(rowData.campaign?.campaign_overall_analytics?.clicks).format({
                  thousandSeparated: true
                })
            },
            {
              title: 'CPC',
              field: 'campaign.campaign_overall_analytics.cpc',
              type: 'currency',
              render: (rowData) =>
                numbro(rowData.campaign?.campaign_overall_analytics?.cpc).formatCurrency({
                  thousandSeparated: true,
                  mantissa: 2
                })
            },
            {
              title: 'Total Spend',
              field: 'campaign.campaign_overall_analytics.spend',
              type: 'currency',
              render: (rowData) =>
                numbro(rowData.campaign?.campaign_overall_analytics?.spend).formatCurrency({
                  thousandSeparated: true,
                  mantissa: 2
                })
            }
          ]}
          isLoading={isLoading}
          options={{
            toolbar: false,
            search: false,
            filtering: false,
            sorting: false,
            paging: true,
            columnResizable: false,
            draggable: false,
            pageSize: 5,
            pageSizeOptions: [5, 10]
          }}
          components={{
            Container: TableContainer
          }}
        />
      </Paper>
    </>
  );
}

interface DateRangeSelectProps {
  value: number;
  setValue: (val: number) => void;
}

function DateRangeSelect({ value, setValue }: DateRangeSelectProps) {
  return (
    <Select
      value={value}
      onChange={(event) => setValue(event.target.value as number)}
      style={{ color: '#F47D6F' }}
      disableUnderline
    >
      <MenuItem value={0}>This Month</MenuItem>
      <MenuItem value={3}>Last 3 Months</MenuItem>
      <MenuItem value={6}>Last 6 Months</MenuItem>
      <MenuItem value={9}>Last 9 Months</MenuItem>
      <MenuItem value={12}>Last 12 Months</MenuItem>
      <MenuItem value={18}>Last 18 Months</MenuItem>
    </Select>
  );
}

function RenderTrophyColumn(
  rowData: { index: number } & WorkspaceCampaignAnalyticsQuery['popular_campaigns'][number]
) {
  if (rowData.index === 1) {
    return <PodiumGoldIcon titleAccess="1st" fontSize="large" htmlColor="#f0b905" />;
  } else if (rowData.index === 2) {
    return <PodiumSilverIcon titleAccess="2nd" fontSize="large" htmlColor="#c0c0c0" />;
  } else if (rowData.index === 3) {
    return <PodiumBronzeIcon titleAccess="3rd" fontSize="large" htmlColor="#cd7f32" />;
  }

  return `${numbro(rowData.index).format({ output: 'ordinal' })}`;
}

function RenderNameColumn(rowData: WorkspaceCampaignAnalyticsQuery['popular_campaigns'][number]) {
  return (
    <MuiLink component={Link} to={`/campaigns/view/${rowData.campaign_id!}`}>
      {rowData.campaign?.name}
    </MuiLink>
  );
}

function RenderPublishDateColumn(
  rowData: WorkspaceCampaignAnalyticsQuery['popular_campaigns'][number]
) {
  if (rowData.campaign?.published_at) {
    return moment(rowData.campaign?.published_at).format('D MMM YYYY h:mm A');
  } else {
    return 'N/A';
  }
}
